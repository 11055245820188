//
// user.scss
// Use this to write your custom SCSS
//

.no-opacity {
  opacity: 1;
}

a span {
  margin-right: 10px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  pointer-events: auto;
  color: #495057;
  // cursor: not-allowed;

  &:focus {
    border-color: #d2ddec;
  }
}

.bg-transparent {
  background-color: transparent;
}

.table-outline {
  th,
  td {
    &:focus {
      outline-width: 3px;
      outline-offset: -3px;
    }
  }
}

.dropdown-ellipses {
  &:focus,
  &:hover {
    cursor: pointer;
  }
}

.flatpickr-calendar {
  .flatpickr-months {
    padding: 0;
    margin-top: 2px;
  }
}

.list-group-flush {
  .list-group-item {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
}

.center-login {
  height: 100vh;
}

.bg-primary-soft {
  background-color: #e6effc !important;
}

.button-sm {
  font-size: 0.9375rem;
}

.card-adjust {
  height: 0;
  min-height: 100%;

  .card {
    height: calc(100% - 1.5rem);
  }

  .card-body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}

.toast {
  right: 0;
}

.list-group-horizontal-lg {
  .list-group-item {
    .column-title {
      display: block;
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75rem;
    }
  }
}

.w-20 {
  width: 20% !important;
}

.double-margin-bottom {
  margin-bottom: 4rem;
}

@media (min-width: 576px) {
  .card-adjust-sm {
    height: 0;
    min-height: 100%;

    .card {
      height: calc(100% - 1.5rem);
    }

    .card-body {
      height: calc(100% - 60px);
      overflow-y: auto;
    }
  }
}

@media (min-width: 768px) {
  .card-adjust-md {
    height: 0;
    min-height: 100%;

    .card {
      height: calc(100% - 1.5rem);
    }

    .card-body {
      height: calc(100% - 60px);
      overflow-y: auto;
    }
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    .list-group-item {
      .column-title {
        position: absolute;
        top: -0.5625rem;
        left: 1rem;
        padding: 0 0.3125rem;
        background-color: #ffffff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.75rem;
      }
    }
  }

  .card-adjust-lg {
    height: 0;
    min-height: 100%;

    .card {
      height: calc(100% - 1.5rem);
    }

    .card-body {
      height: calc(100% - 60px);
      overflow-y: auto;
    }
  }

  .w-lg-20 {
    width: 20% !important;
  }
}

@media (min-width: 1200px) {
  .card-adjust-xl {
    height: 0;
    min-height: 100%;

    .card {
      height: calc(100% - 1.5rem);
    }

    .card-body {
      height: calc(100% - 60px);
      overflow-y: auto;
    }
  }
  .printable {
    tbody {
      vertical-align: baseline;

      tr {
        td {
          display: table-cell;
          width: 65%;
          + td {
            text-align: end;
          }
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .custom-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1600px) {
  .custom-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
    clear: both;
  }
  .score-card-container {
    max-width: 33%;
  }
  .card {
    border: none;
    box-shadow: none;
  }
  .card-header {
    border-bottom: 2px solid #edf2f9;
    margin: 0 1.5rem;
    padding-left: 0;
    padding-right: 0;
  }
  .header-pretitle {
    color: #12263f;
  }
  .printable {
    tbody {
      vertical-align: baseline;

      tr {
        td {
          display: table-cell;
          width: 55%;
          + td {
            text-align: end;
          }
        }
      }
    }
    .side-by-side {
      display: table-row-group;
      div {
        display: table-cell !important;
        width: 300px;
        padding: 0;
      }
    }
    .mt-print {
      margin-top: 1.5rem;
    }
  }
}

@page {
  size: auto;
  margin: 4mm;
}

.custom-margin-table {
  margin-top: -0.9375rem;
  margin-bottom: -0.9375rem;
}

.table-fixed {
  table-layout: fixed;
}

.select2-selection__rendered {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0 -0.25rem -0.25rem 0;
}

.select2-selection__choice {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  margin: 0 0.25rem 0.25rem 0;
  font-size: 0.8125rem;
  background-color: #edf2f9;
  border-radius: 0.1875rem;
}

.select2-selection__choice__remove {
  -webkit-box-ordinal-group: 3;
  order: 2;
  margin-left: 0.5rem;
  color: #95aac9;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  cursor: pointer;
}

/**
 * hide the arrow for numeric inputs
 *
 */
.result-row-input-numeric {
  /**
   *WebKit based browser
   */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Mozilla
  -moz-appearance: textfield;
}

.toast-container {
  position: fixed;
  top: 1.875rem;
  right: 0.625rem;
  z-index: 1040;
}

.colorBlindRed {
  color: #bb1c1c !important;
}
.colorBlindYellow {
  color: #ffc20a !important;
}

.custom-text-area {
  font-size: 0.8125rem !important;
  width: 185px !important;
  height: 60px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.fixed-height {
  td {
    height: 5.6875rem;
    vertical-align: middle;
  }
}

.study-progress-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}