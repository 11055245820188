// 
// list-group.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

// Contextual variants
//
// Changing the Bootstrap color modifier classes to be full opacity background with yiq calculated font color

@each $color, $value in $theme-colors {
  @include list-group-item-variant($color, $value, color-yiq($value));
}


// List group large

.list-group-lg .list-group-item {
  padding-top: $list-group-item-padding-y-lg;
  padding-bottom: $list-group-item-padding-y-lg;
}